<template>

</template>

<script>
    export default {
        name: "checkJoinMember"
    }
</script>

<style scoped>

</style>
